import { LoginPage } from '@/pages';
import { IAuthContext } from '@/types';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { inProgress, accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const login = useCallback(async () => {
    try {
      if (inProgress !== InteractionStatus.None) {
        return;
      }

      if (accounts.length === 0) {
        await instance.loginRedirect();
      } else {
        instance.setActiveAccount(accounts[0] as AccountInfo);
      }
    } catch (error) {
      throw error;
    }
  }, [accounts, instance, inProgress]);

  const logout = useCallback(async () => {
    try {
      await instance.logout();
    } catch (error) {
      throw error;
    }
  }, [instance]);

  const value: IAuthContext = useMemo(
    () => ({
      account: accounts[0],
      isAuthenticated,
      token: accounts[0] ? accounts[0].idToken ?? '' : '',
      handleLogin: login,
      logout,
    }),
    [accounts, isAuthenticated, login, logout],
  );

  return (
    <AuthContext.Provider value={value}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </AuthContext.Provider>
  );
};

export const useAuthContext: () => IAuthContext = () => useContext(AuthContext);
