import { IPageProps, Page, ProductNavbar } from '@/components';
import { VStack } from '@chakra-ui/react';
import { FC } from 'react';

export const ProductPageContainer: FC<IPageProps> = ({ title, description, ...props }) => {
  return (
    <Page description={description} title={title}>
      <ProductNavbar />
      <VStack align="flex-start" mt={{ base: 24, md: 16 }} w="full" {...props} />
    </Page>
  );
};
