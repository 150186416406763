/* eslint-disable max-lines-per-function */
import { CodeBlock, Page, PageTitle, Typography } from '@/components';
import { useAmmaContext } from '@/contexts';
import { IAmmaClient } from '@/types';
import { HStack, VStack } from '@chakra-ui/react';
import { Button } from '@lego/connect-components-react';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const ClientPage: FC = () => {
  const { clientName } = useParams();

  const { clients } = useAmmaContext();
  const [client, setClient] = useState<IAmmaClient>();

  useEffect(() => {
    const selectedClient = clients.find(({ name }) => name === clientName);
    setClient(selectedClient);
  }, [clientName, clients]);

  if (!client || !clientName) {
    return null;
  }

  const getExpiryDateColor = (expiryDate: string): string => {
    const now = DateTime.now();
    const twoWeeksAhead = now.plus({ weeks: 2 });

    switch (true) {
      case DateTime.fromISO(expiryDate) <= now:
        return 'var(--text-danger)';
      case DateTime.fromISO(expiryDate) > now && DateTime.fromISO(expiryDate) < twoWeeksAhead:
        return 'var(--text-warning)';
      default:
        return 'var(--text-success)';
    }
  };

  const addSecret = () => {
    // eslint-disable-next-line no-console
    console.log('addSecret ~ addSecret:');
  };

  const addConnection = () => {
    // eslint-disable-next-line no-console
    console.log('addConnection ~ addConnection:');
  };

  return (
    <Page description={clientName} gap={{ base: 16, md: 24, lg: 32 }} title={clientName}>
      <VStack align="flex-start">
        <PageTitle>{clientName}</PageTitle>
        <Typography>
          Welcome to the client management page, your dedicated interface for overseeing your client
          application&apos;s secrets and API connections. Here, you can efficiently manage your
          client&apos;s secrets by creating, updating, and revoking them as needed, ensuring robust
          security for your application. Each secret is securely stored and easily accessible, with
          options to define specific scopes and expiration dates to tailor permissions precisely.
        </Typography>
        <Typography>
          In addition, this page provides a streamlined view of all API connections associated with
          your client application. You can effortlessly add new API connections, specify the
          required scopes, and manage existing ones. Whether updating scopes, refreshing tokens, or
          disconnecting APIs, this interface ensures your application maintains optimal integration
          and functionality.
        </Typography>
      </VStack>
      <VStack align="flex-start" gap={4} w="full">
        <Typography size="lg" variant="heading">
          Secrets
        </Typography>
        <VStack maxW="800px" p={0} w="full">
          {client.secrets.map(({ expiresAt, value }) => (
            <HStack
              align="center"
              borderBottom="1px solid var(--ds-color-core-slate-1200)"
              justify="space-between"
              key={value}
              p={0}
              w="full"
            >
              <Typography>{value}</Typography>
              <HStack style={{ padding: 0, alignItems: 'center' }}>
                <Typography style={{ color: getExpiryDateColor(expiresAt) }}>
                  {DateTime.fromISO(expiresAt).toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
                <Button
                  ariaLabel="Refresh secret"
                  dataStyle="ghost"
                  iconName="arrows-rotate"
                  iconPosition="icon-only"
                  text=""
                />
                <Button
                  ariaLabel="Delete secret"
                  dataStyle="ghost"
                  iconName="trash"
                  iconPosition="icon-only"
                  text=""
                  variant="negative"
                />
              </HStack>
            </HStack>
          ))}
        </VStack>
        <Button
          ariaLabel="Add secret"
          iconName="control-plus"
          onClick={addSecret}
          style={{ width: 'fit-content' }}
          text="Add secret"
        />
      </VStack>
      <VStack align="flex-start" gap={4} w="full">
        <Typography size="lg" variant="heading">
          Access
        </Typography>
        <VStack maxW="800px" p={0} w="full">
          {client.connections.map(({ connectedTo, scope }) => (
            <HStack
              align="center"
              borderBottom="1px solid var(--ds-color-core-slate-1200)"
              justify="space-between"
              key={connectedTo}
              p={0}
              w="full"
            >
              <Typography>{connectedTo}</Typography>
              <CodeBlock code={scope} language="plaintext" />
            </HStack>
          ))}
        </VStack>
        <Button
          ariaLabel="Request access"
          iconName="control-plus"
          onClick={addConnection}
          style={{ width: 'fit-content' }}
          text="Request access"
        />
      </VStack>
    </Page>
  );
};
