import { Typography } from '@/components';
import { TagVariant } from '@/types';
import clsx from 'clsx';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import styles from './Tag.module.scss';

export interface ITagProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  text: string;
  variant?: TagVariant;
  size?: 'lg' | 'md' | 'sm';
  uppercase?: boolean;
}

export const Tag: FC<ITagProps> = ({
  text,
  variant = 'neutral',
  className,
  size = 'md',
  uppercase = true,
  ...props
}) => {
  const combinedClasses = clsx(styles[`container-${variant}`], className);

  return (
    <div className={combinedClasses} {...props}>
      <Typography size={size}>{uppercase ? text.toUpperCase() : text}</Typography>
    </div>
  );
};
