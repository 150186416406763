import { EntityCard, Page, PageTitle, Typography } from '@/components';
import { useAmmaContext } from '@/contexts';
import { ISecret } from '@/types';
import { generateRandomString } from '@/utils';
import { Grid, GridItem, VStack } from '@chakra-ui/react';
import { Button } from '@lego/connect-components-react';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const ClientsPage: FC = () => {
  const { clients, createClient } = useAmmaContext();

  const getSecretsTagVariant = (secrets: ISecret[]): 'error' | 'info' | 'warning' => {
    const now = DateTime.now();
    const twoWeeksAhead = now.plus({ weeks: 2 });

    switch (true) {
      case secrets.some((secret) => DateTime.fromISO(secret.expiresAt) <= now):
        return 'error';
      case secrets.some(
        (secret) =>
          DateTime.fromISO(secret.expiresAt) > now &&
          DateTime.fromISO(secret.expiresAt) < twoWeeksAhead,
      ):
        return 'warning';
      default:
        return 'info';
    }
  };

  return (
    <Page description="Clients" title="Clients">
      <VStack align="flex-start" gap={12}>
        <VStack align="flex-start" maxW="800px">
          <PageTitle>Your clients</PageTitle>
          <Typography size="sm" style={{ color: 'var(--text-secondary)' }}>
            Manage your API clients efficiently. Use this page to create new clients, connect them
            to multiple APIs, and perform actions such as adding, regenerating, or deleting secrets.
            Simplify your API integrations with easy client management.
          </Typography>
        </VStack>
        {clients.length === 0 && (
          // TODO: improve text below
          <Typography size="lg">No clients found</Typography>
        )}
        <Grid gap={4} templateColumns="repeat(12, minmax(0, 1fr))" w="full">
          {clients.map(({ name, connections, secrets }) => (
            <GridItem
              colSpan={{ base: 12, md: 6, lg: 4 }}
              key={`${name}-${generateRandomString()}`}
            >
              <Link to={`/clients/${name}`}>
                <EntityCard
                  bodyContent={connections.map((connection) => connection.connectedTo)}
                  emptyBodyText="No connections"
                  tag={`${secrets.length} secrets`}
                  tagVariant={getSecretsTagVariant(secrets)}
                  title={name}
                />
              </Link>
            </GridItem>
          ))}
        </Grid>
        <Button
          ariaLabel="Create client"
          iconName="control-plus"
          onClick={createClient}
          style={{ width: 'fit-content' }}
          text="Create client"
        />
      </VStack>
    </Page>
  );
};
