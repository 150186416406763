import { DiverIllustration, Metadata, Typography } from '@/components';
import { useAuthContext } from '@/contexts';
import { VStack } from '@chakra-ui/react';
import { Button } from '@lego/connect-components-react';
import { FC } from 'react';

export const LoginPage: FC = () => {
  const { handleLogin } = useAuthContext();

  return (
    <VStack
      align="center"
      bg="var(--background-accent)"
      justifyContent="center"
      minH="100vh"
      minW="100vw"
    >
      <Metadata description="LEGO Developer Portal" title="LEGO Developer Portal" />
      <DiverIllustration />
      <VStack w="full" zIndex={1}>
        <Typography size="2xl" variant="heading">
          LEGO Developer Portal
        </Typography>
        <Button ariaLabel="Login button" onClick={handleLogin} size="large" text="Login" />
      </VStack>
    </VStack>
  );
};
