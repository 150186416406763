import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    authority: 'https://login.microsoftonline.com/legogroup.onmicrosoft.com',
    // hack to get the auth redirect to play well with NextJS on localhost
    redirectUri: typeof window !== 'undefined' ? window.location.origin : 'http://localhost:8080',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};
