import { CodeBlock, ProductPageContainer } from '@/components';
import { getSpec } from '@/services';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const ProductRawSpecificationPage: FC = () => {
  const { productName } = useParams();

  const [rawSpecification, setRawSpecification] = useState<unknown>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    void loadSpec();
  }, [productName]);

  if (!productName) {
    return null;
  }

  const loadSpec = async () => {
    const rawSpec = await getSpec(productName);
    setRawSpecification(rawSpec);
  };

  return (
    <ProductPageContainer
      description={`${productName} specification`}
      title={`${productName} specification`}
    >
      <CodeBlock code={JSON.stringify(rawSpecification, undefined, 2)} showLineNumbers={true} />
    </ProductPageContainer>
  );
};
