import { Navbar } from '@/components';
import { Box } from '@chakra-ui/react';
import { FC, Fragment, PropsWithChildren } from 'react';

export const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Fragment>
      <Navbar />
      <Box m="0 auto" maxW="100rem" minH="calc(100vh - 92px)">
        {children}
      </Box>
    </Fragment>
  );
};
