import { EngineersIllustration, Typography } from '@/components';
import { VStack } from '@chakra-ui/react';
import { FC } from 'react';

export const CustomErrorPage: FC = () => {
  return (
    <VStack
      align="center"
      bg="var(--ds-color-core-pink-1200)"
      justifyContent="center"
      minH="100vh"
      minW="100vw"
    >
      <EngineersIllustration />
      <VStack style={{ maxWidth: '400px', zIndex: 1, color: 'var(--text-inverted)' }}>
        <Typography size="xl" variant="heading">
          Alright, everyone, let&apos;s make it look exactly like it does in the instruction.
        </Typography>
        <Typography size="lg">
          Oops! It looks like one of our LEGO bricks went missing. But don&apos;t worry, our
          engineers are already on the case, following the instructions to get everything back in
          place. Please try again soon.
        </Typography>
      </VStack>
    </VStack>
  );
};
