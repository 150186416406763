import { IAmmaApi, IAmmaWorkspace } from '@/types';
import workspaces from '../data/ammaWorkspaces.json';

export const getProducts = (): Promise<IAmmaApi[]> => {
  try {
    const ammaWorkspaces = workspaces as IAmmaWorkspace[];

    return Promise.resolve(
      ammaWorkspaces
        .flatMap((workspace) => workspace.apis)
        // assume that only APIs have specifications, clients will not
        .filter((api) => api.specifications.length > 0),
    );
  } catch (error) {
    throw error;
  }
};
