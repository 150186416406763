import { NavbarLink, Typography } from '@/components';
import { Divider, HStack, Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styles from './Navbar.module.scss';

export const ProductNavbar: FC = () => {
  const { productName } = useParams();
  const { pathname } = useLocation();

  if (!productName) {
    return null;
  }

  return (
    <Stack
      align="center"
      bg="var(--background-secondary)"
      left={0}
      position="absolute"
      px={4}
      right={0}
      top={92}
    >
      <HStack h="62px" maxW="100rem" p={4} spacing={12} w="full">
        <Typography
          className={styles.navbarItem}
          size="lg"
          style={{
            borderBottom: '3px solid',
            borderColor: 'transparent',
          }}
        >
          {productName}
        </Typography>
        <Divider borderColor="var(--text-primary)" orientation="vertical" />
        <HStack spacing={8}>
          <NavbarLink
            fontWeight={400}
            href={`/products/${productName}`}
            isActive={pathname === `/products/${productName}`}
            title="Overview"
          />
          <NavbarLink
            fontWeight={400}
            href={`/products/${productName}/specification`}
            title="Specification"
          />
          <NavbarLink
            display={{ base: 'none', md: 'block' }}
            fontWeight={400}
            href={`/products/${productName}/raw-specification`}
            title="Raw specification"
          />
        </HStack>
      </HStack>
    </Stack>
  );
};
