import { SpacemanIllustration, Typography } from '@/components';
import { VStack } from '@chakra-ui/react';
import { Button } from '@lego/connect-components-react';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const Custom404: FC = () => {
  return (
    <VStack
      align="center"
      bg="var(--ds-color-core-purple-1200)"
      justifyContent="center"
      minH="100vh"
      minW="100vw"
    >
      <SpacemanIllustration />
      <VStack align="flex-start" color="var(--text-inverted)" maxW="400px" w="full" zIndex={1}>
        <Typography size="3xl" variant="heading">
          404
        </Typography>
        <Typography size="xl" variant="heading">
          Billund, we have a problem.
        </Typography>
        <Typography size="lg">This link may be broken or the page may have been moved.</Typography>
        <Link to="/">
          <Button
            ariaLabel="Home"
            dataStyle="ghost"
            iconName="house-solid"
            size="large"
            style={{ width: 'fit-content' }}
            text="Bring me home"
            variant="static-light"
          />
        </Link>
      </VStack>
    </VStack>
  );
};
