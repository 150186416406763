import { MenuItem, MenuItemProps } from '@chakra-ui/react';
import { FC } from 'react';

export const NavbarMenuItem: FC<MenuItemProps> = (props) => {
  return (
    <MenuItem
      _active={{ bg: 'var(--menu-background)' }}
      _focus={{ bg: 'var(--menu-background)' }}
      _hover={{ bg: 'var(--menu-background)' }}
      bg="var(--menu-background)"
      px={4}
      py={3}
      {...props}
    />
  );
};
