import { IAmmaClient, IAmmaWorkspace, ISecret } from '@/types';
import { DateTime } from 'luxon';
import workspaces from '../data/ammaWorkspaces.json';

const getRandomDate = (): string => {
  const now = DateTime.now();
  const pastDate = now.minus({ months: 1 });
  const twelveMonthsAhead = now.plus({ months: 12 });

  const randomDate =
    Math.floor(Math.random() * (twelveMonthsAhead.toMillis() - pastDate.toMillis())) +
    pastDate.toMillis();

  return DateTime.fromMillis(randomDate).toUTC().toISO() ?? '';
};

const getRandomString = (length = 3): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

const generateRandomSecrets = (min = 1, max = 10): ISecret[] => {
  const numberOfSecrets = Math.floor(Math.random() * (max - min + 1)) + min;
  const secrets: ISecret[] = [];

  for (let i = 0; i < numberOfSecrets; i += 1) {
    const secret: ISecret = {
      value: `${getRandomString()}******************`,
      expiresAt: getRandomDate(),
    };

    secrets.push(secret);
  }

  return secrets;
};

export const getClients = (): Promise<IAmmaClient[]> => {
  try {
    const ammaWorkspaces = workspaces as IAmmaWorkspace[];

    return Promise.resolve(
      ammaWorkspaces
        .flatMap((workspace) => workspace.apis)

        // assume that only APIs have specifications, clients will not
        .filter((api) => api.specifications.length === 0)
        .map((item) => ({ ...item, secrets: generateRandomSecrets() })),
    );
  } catch (error) {
    throw error;
  }
};
