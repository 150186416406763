import { DefaultLayout, Metadata } from '@/components';
import { Stack, StackProps } from '@chakra-ui/react';
import { FC, Fragment, PropsWithChildren } from 'react';

export interface IPageProps extends PropsWithChildren<StackProps> {
  title: string;
  description: string;
  defaultLayout?: boolean;
}

export const Page: FC<IPageProps> = ({ title, description, defaultLayout = true, ...props }) => {
  const pageContent = (
    <Fragment>
      <Metadata description={description} title={title} />
      <Stack align="flex-start" gap={8} h="full" justify="center" p={8} w="full" {...props} />
    </Fragment>
  );

  return defaultLayout ? <DefaultLayout>{pageContent}</DefaultLayout> : pageContent;
};
