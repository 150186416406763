import { EntityCard, Page, PageTitle, Typography } from '@/components';
import { useAmmaContext } from '@/contexts';
import { generateRandomString } from '@/utils';
import { Grid, GridItem, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const ProductsPage: FC = () => {
  const { products } = useAmmaContext();

  return (
    <Page description="Products" title="Products">
      <VStack align="flex-start" gap={12}>
        <VStack align="flex-start" maxW="800px">
          <PageTitle>All Products</PageTitle>
          <Typography size="sm" style={{ color: 'var(--text-secondary)' }}>
            Explore and request access to a comprehensive collection of LEGO APIs and other digital
            products available to you.
          </Typography>
        </VStack>
        {products.length === 0 && (
          // TODO: improve text below
          <Typography size="lg">No products found</Typography>
        )}

        <Grid gap={4} templateColumns="repeat(12, minmax(0, 1fr))" w="full">
          {products.map(({ name, description }) => (
            <GridItem
              colSpan={{ base: 12, md: 6, lg: 4 }}
              key={`${name}-${generateRandomString()}`}
            >
              <Link to={`/products/${name}`}>
                <EntityCard
                  apiStatus="healthy"
                  bodyContent={[description]}
                  tag="openapi"
                  tagVariant="openapi"
                  title={name}
                />
              </Link>
            </GridItem>
          ))}
        </Grid>
      </VStack>
    </Page>
  );
};
