import { IAmmaApi } from './amma';

export enum TeamMemberRoles {
  'LEGOContact' = 'LEGO Contact',
  Admin = 'Admin',
  Edit = 'Edit',
  View = 'View',
}
export interface ITeamMember {
  name: string;
  email: string;
  role: TeamMemberRoles;
}

export interface ITeam {
  name: string;
  members: ITeamMember[];
  connections: IAmmaApi[];
}

export interface ITeamsContext {
  teams: ITeam[];
  addTeam: (newTeam: ITeam) => Promise<void>;
  addTeamMember: (teamName: string, email: string) => Promise<void>;
}
