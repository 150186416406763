import { ProductPageContainer } from '@/components';
import { getSpec } from '@/services';
import { ApiReferenceReact } from '@scalar/api-reference-react';
import type { ReferenceConfiguration } from '@scalar/types/legacy';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const ProductSpecificationPage: FC = () => {
  const { productName } = useParams();

  const [rawSpecification, setRawSpecification] = useState<string>('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    void loadSpec();
  }, [productName]);

  if (!productName) {
    return null;
  }

  const loadSpec = async () => {
    const rawSpec = await getSpec(productName);
    setRawSpecification(JSON.stringify(rawSpec, undefined, 2));
  };

  const specRendererConfig: ReferenceConfiguration = {
    spec: {
      content: rawSpecification,
    },
    withDefaultFonts: false,
    hideTestRequestButton: true,
    defaultOpenAllTags: true,
    darkMode: false,
  };

  return (
    <ProductPageContainer
      description={`${productName} specification`}
      title={`${productName} specification`}
    >
      <ApiReferenceReact configuration={specRendererConfig} />
    </ProductPageContainer>
  );
};
