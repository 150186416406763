import { FC, Fragment } from 'react';

export interface IMetadataProps {
  title: string;
  description: string;
}

export const Metadata: FC<IMetadataProps> = ({ title, description }) => {
  return (
    <Fragment>
      <title>{title}</title>
      <meta content={description} name="description" />
    </Fragment>
  );
};
