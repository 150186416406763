import clsx from 'clsx';
import { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react';

interface IHeadingTypographyProps
  extends PropsWithChildren,
    DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  variant?: 'body' | 'heading' | 'label';
  size?: '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'lg' | 'md' | 'sm' | 'xl';
  weight?: 'bold' | 'medium' | 'regular';
}

interface IBodyTypographyProps
  extends PropsWithChildren,
    DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  variant?: 'body';
  size?: 'lg' | 'md' | 'sm';
  weight?: 'bold' | 'medium' | 'regular';
}

export type ITypographyProps = IBodyTypographyProps | IHeadingTypographyProps;

export const Typography: FC<IBodyTypographyProps | IHeadingTypographyProps> = ({
  variant = 'body',
  size = 'md',
  weight = 'regular',
  className,
  ...props
}) => {
  if (variant === 'heading') {
    const combinedClasses = clsx(`${variant}-${size}`, className);

    return <h1 className={combinedClasses} {...props} />;
  }

  const combinedClasses = clsx(`${variant}-${size}-${weight}`, className);

  return <p className={combinedClasses} {...props} />;
};
