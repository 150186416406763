import { ITeam, TeamMemberRoles } from '@/types';

export const getTeamsData = async (): Promise<ITeam[]> => {
  try {
    const teams: ITeam[] = [
      {
        name: 'Alpha Team',
        members: [
          {
            name: 'César Roux dit Buisson',
            email: 'cesar.roux@lego.com',
            role: TeamMemberRoles.LEGOContact,
          },
          { name: 'Bob Smith', email: 'bob.smith@example.com', role: TeamMemberRoles.Edit },
          { name: 'Dana Lee', email: 'dana.lee@example.com', role: TeamMemberRoles.Admin },
        ],
        connections: [
          {
            name: 'UserManagementAPI',
            description: '',
            scopes: ['read', 'write'],
            connections: [
              { connectedTo: 'DatabaseService', scope: 'read', metadata: { priority: 'high' } },
              { connectedTo: 'LoggingService', scope: 'write', metadata: { retries: 3 } },
            ],
            identity: 'api_alpha_usermanagement',
            specifications: [{ type: 'REST', href: 'https://api.example.com/usermanagement/docs' }],
            metadata: { version: '1.0', region: 'US' },
          },
          {
            name: 'OrderProcessingAPI',
            description: '',
            scopes: ['read'],
            connections: [
              { connectedTo: 'PaymentGateway', scope: 'write', metadata: { timeout: '30s' } },
            ],
            identity: 'api_alpha_orderprocessing',
            specifications: [
              { type: 'GraphQL', href: 'https://api.example.com/orderprocessing/docs' },
            ],
            metadata: { version: '2.1', region: 'EU' },
          },
        ],
      },
      {
        name: 'Beta Team',
        members: [
          {
            name: 'César Roux dit Buisson',
            email: 'cesar.roux@lego.com',
            role: TeamMemberRoles.LEGOContact,
          },
          { name: 'Dana Lee', email: 'dana.lee@example.com', role: TeamMemberRoles.Admin },
          { name: 'Bob Smith', email: 'bob.smith@example.com', role: TeamMemberRoles.Edit },
        ],
        connections: [
          {
            name: 'InventoryAPI',
            description: '',
            scopes: ['read', 'write', 'update'],
            connections: [
              { connectedTo: 'WarehouseService', scope: 'update', metadata: { audit: true } },
              { connectedTo: 'ShippingService', scope: 'read', metadata: { priority: 'medium' } },
            ],
            identity: 'api_beta_inventory',
            specifications: [{ type: 'SOAP', href: 'https://api.example.com/inventory/docs' }],
            metadata: { version: '1.2', region: 'Asia' },
          },
        ],
      },
      {
        name: 'Gamma Team',
        members: [
          {
            name: 'César Roux dit Buisson',
            email: 'cesar.roux@lego.com',
            role: TeamMemberRoles.LEGOContact,
          },
          { name: 'Dana Lee', email: 'dana.lee@example.com', role: TeamMemberRoles.Admin },
          { name: 'Bob Smith', email: 'bob.smith@example.com', role: TeamMemberRoles.Edit },
        ],
        connections: [
          {
            name: 'AnalyticsAPI',
            description: '',
            scopes: ['read'],
            connections: [
              { connectedTo: 'DataWarehouse', scope: 'read', metadata: { retention: '7 days' } },
              { connectedTo: 'NotificationService', scope: 'write', metadata: { retries: 5 } },
            ],
            identity: 'api_gamma_analytics',
            specifications: [{ type: 'REST', href: 'https://api.example.com/analytics/docs' }],
            metadata: { version: '3.0', region: 'Global' },
          },
          {
            name: 'NotificationAPI',
            description: '',
            scopes: ['write'],
            connections: [
              { connectedTo: 'EmailService', scope: 'write', metadata: { priority: 'high' } },
            ],
            identity: 'api_gamma_notification',
            specifications: [
              { type: 'WebSocket', href: 'https://api.example.com/notification/docs' },
            ],
            metadata: { version: '2.0', region: 'NA' },
          },
        ],
      },
      {
        name: 'Delta Team',
        members: [],
        connections: [],
      },
    ];

    return Promise.resolve(teams);
  } catch (error) {
    throw error;
  }
};
