import { PageTitle, ProductPageContainer, Typography } from '@/components';
import { VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

export const ProductPage: FC = () => {
  const { productName } = useParams();

  if (!productName) {
    return null;
  }

  return (
    <ProductPageContainer description={`${productName} overview`} maxW="600px" title={productName}>
      <VStack>
        <PageTitle>{productName}</PageTitle>
        <Typography size="sm">
          With this product, you can do all sorts of LEGO related automated tasks. Instead of
          relying on third party sources, you can now get the official data straight from the
          source, always up to date.
        </Typography>
      </VStack>
      <VStack>
        <Typography size="xl" variant="heading">
          Things this API can do for you
        </Typography>
        <Typography size="sm">
          Do amet ut labore deserunt adipisicing consequat laboris ad cillum duis consectetur
          exercitation. Ex eiusmod fugiat mollit occaecat sit et adipisicing ullamco reprehenderit
          amet fugiat consequat aliquip amet. Velit aliqua deserunt esse mollit. Aliqua ad qui
          nostrud ex cupidatat laboris.
        </Typography>
        <Typography size="sm">
          Do amet ut labore deserunt adipisicing consequat laboris ad cillum duis consectetur
          exercitation. Ex eiusmod fugiat mollit occaecat sit et adipisicing ullamco reprehenderit
          amet fugiat consequat aliquip amet. Velit aliqua deserunt esse mollit. Aliqua ad qui
          nostrud ex cupidatat laboris.
        </Typography>
      </VStack>
    </ProductPageContainer>
  );
};
