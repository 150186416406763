import { getClients, getProducts } from '@/services';
import { IAmmaApi, IAmmaClient, IAmmaContext } from '@/types';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuthContext } from './AuthContext';

export const AmmaContext = createContext<IAmmaContext>({} as IAmmaContext);

export const AmmaContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [products, setProducts] = useState<IAmmaApi[]>([]);
  const [clients, setClients] = useState<IAmmaClient[]>([]);
  const { isAuthenticated } = useAuthContext();

  const getData = async () => {
    try {
      const productsData = await getProducts();
      setProducts(productsData);
      const clientsData = await getClients();
      setClients(clientsData);
    } catch (error) {
      throw error;
    }
  };

  const createClient = () => {
    // eslint-disable-next-line no-console
    console.log('create client clicked');
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    void getData();
  }, [isAuthenticated]);

  const value: IAmmaContext = useMemo(
    () => ({
      products,
      clients,
      createClient,
    }),
    [products, clients],
  );

  return <AmmaContext.Provider value={value}>{children}</AmmaContext.Provider>;
};

export const useAmmaContext: () => IAmmaContext = () => useContext(AmmaContext);
