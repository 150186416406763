import { LEGOLogo, NavbarLink, Typography } from '@/components';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.scss';

export const NavbarDrawer: FC<Omit<DrawerProps, 'children'>> = (props) => {
  return (
    <Drawer placement="right" {...props}>
      <DrawerOverlay />
      <DrawerContent bg="white">
        <DrawerCloseButton top={5} />
        <DrawerHeader borderBottom="2px solid var(--background-accent)">
          <Link to="/">
            <HStack cursor="pointer" spacing={4}>
              <LEGOLogo style={{ height: '40px' }} />
              <Typography className={styles.navbarItem} size="lg">
                Developer Portal
              </Typography>
            </HStack>
          </Link>
        </DrawerHeader>
        <DrawerBody py={4}>
          <VStack align="flex-start" spacing={4} w="full">
            <NavbarLink href="/get-started" title="Get started" />
            <NavbarLink href="/products" title="Products" />
            <NavbarLink href="/clients" title="Clients" />
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
