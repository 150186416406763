import { FC } from 'react';
import SyntaxHighlighter, { SyntaxHighlighterProps } from 'react-syntax-highlighter';
import { foundation } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styles from './CodeBlock.module.scss';

export interface ICodeBlockProps extends Omit<SyntaxHighlighterProps, 'children'> {
  code: string;
  language?: string;
  customStyle?: React.CSSProperties;
}

export const CodeBlock: FC<ICodeBlockProps> = ({
  code,
  language = 'json',
  customStyle,
  ...props
}) => {
  // const colorMode = useColorModePreference();

  return (
    <SyntaxHighlighter
      customStyle={{
        fontSize: '16px',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        background: 'inherit',
        ...customStyle,
      }}
      language={language}
      // style={colorMode === 'dark' ? dracula : docco}
      style={foundation}
      {...props}
      codeTagProps={{ className: styles.codeBlock }}
    >
      {code}
    </SyntaxHighlighter>
  );
};
