import { PageTitle, Typography } from '@/components';
import { Page } from '@/components/Page';
import { VStack } from '@chakra-ui/react';
import { FC } from 'react';

export const Home: FC = () => {
  return (
    <Page align="center" description="LEGO Developer Portal" title="LEGO Developer Portal">
      <VStack maxW="600px">
        <PageTitle>LEGO Developer Portal</PageTitle>
        <Typography size="md">
          Welcome to the LEGO Developer Portal, your comprehensive platform for discovering,
          connecting, and managing a wide range of LEGO APIs. Designed with developers in mind, our
          portal provides the tools and resources necessary to seamlessly integrate LEGO&apos;s vast
          array of data and services into your applications.
        </Typography>
        <Typography size="md">
          The LEGO Developer Portal allows you to explore a diverse collection of APIs, enabling you
          to find the perfect solutions for your development needs. Our intuitive interface ensures
          that you can easily connect to the APIs, facilitating a smooth integration process.
        </Typography>
        <Typography size="md">
          Managing your integration is seamless with our portal&apos;s robust features. You can
          efficiently manage your secrets and workspace, ensuring secure and organized access to
          your API keys and credentials. This secure management system allows you to set up and
          maintain secure connections to the APIs you need, providing a streamlined development
          experience.
        </Typography>
        <Typography size="md">
          Choosing the LEGO API means leveraging the power and popularity of the LEGO brand to
          enhance your applications. Inspire creativity and innovation by integrating LEGO&apos;s
          iconic experiences into your digital solutions. Our APIs are designed with developers in
          mind, offering intuitive endpoints, clear documentation, and responsive support.
        </Typography>
        <Typography size="md">
          Join us in building the future of play and creativity. Start exploring the LEGO Developer
          Portal today and see how you can bring the joy of LEGO to your users in new and exciting
          ways!
        </Typography>
      </VStack>
    </Page>
  );
};
