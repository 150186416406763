import { NavbarMenuItem } from '@/components';
import { useAuthContext } from '@/contexts';
import { Avatar, Menu, MenuButton, MenuDivider, MenuList } from '@chakra-ui/react';
import { Icon } from '@lego/connect-components-react';
import { FC } from 'react';

export const NavbarAvatar: FC = () => {
  const { logout, account } = useAuthContext();

  return (
    <Menu offset={[-190, 10]}>
      <MenuButton cursor="pointer" ml={16} rounded="full">
        <Avatar
          bg="unset"
          icon={<Icon ariaLabel="profile" name="minifigure-faceless-1" />}
          size="sm"
          style={{ color: 'var(--text-primary)' }}
        />
      </MenuButton>
      <MenuList bg="var(--menu-background)" p={4}>
        <NavbarMenuItem cursor="unset">{account.name}</NavbarMenuItem>
        <MenuDivider />
        <NavbarMenuItem
          icon={<Icon ariaLabel="logout" name="arrow-right-from-bracket" size="x-small" />}
          onClick={logout}
        >
          Logout
        </NavbarMenuItem>
      </MenuList>
    </Menu>
  );
};
