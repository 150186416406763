import { getTeamsData } from '@/services';
import { ITeam, ITeamsContext, TeamMemberRoles } from '@/types';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const TeamsContext = createContext<ITeamsContext>({} as ITeamsContext);

export const TeamsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [teams, setTeams] = useState<ITeam[]>([]);

  const getTeams = async () => {
    try {
      const teamsData = await getTeamsData();
      setTeams(teamsData);
    } catch (error) {
      throw error;
    }
  };

  const addTeam = async (newTeam: ITeam) => {
    try {
      setTeams([...teams, newTeam]);
      await Promise.resolve();
    } catch (error) {
      throw error;
    }
  };

  const addTeamMember = async (teamName: string, email: string) => {
    try {
      const newTeams = teams.map((team) => {
        if (team.name === teamName) {
          return {
            ...team,
            members: [...team.members, { email, name: 'John Smith', role: TeamMemberRoles.View }],
          };
        }

        return team;
      });

      setTeams(newTeams);
      await Promise.resolve();
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    void getTeams();
  }, []);

  const value: ITeamsContext = useMemo(
    () => ({
      teams,
      addTeam,
      addTeamMember,
    }),
    [teams],
  );

  return <TeamsContext.Provider value={value}>{children}</TeamsContext.Provider>;
};

export const useTeamsContext: () => ITeamsContext = () => useContext(TeamsContext);
