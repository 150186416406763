import { PageTitle } from '@/components';
import { Page } from '@/components/Page';
import { FC } from 'react';

export const GetStartedPage: FC = () => {
  return (
    <Page description="Get started" title="Get started">
      <PageTitle>Get started page</PageTitle>
    </Page>
  );
};
