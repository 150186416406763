import { Typography } from '@/components';
import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface INavbarLinkProps extends Omit<BoxProps, 'fontWeight'> {
  href: string;
  title: string;
  isActive?: boolean;
  fontWeight?: number | string;
}

export const NavbarLink: FC<INavbarLinkProps> = ({
  href,
  title,
  isActive,
  fontWeight = 500,
  ...props
}) => {
  const { pathname } = useLocation();

  const getBorderColor = (): string => {
    if (typeof isActive === 'undefined') {
      if (pathname.startsWith(href)) {
        return 'var(--text-primary)';
      }
    }

    if (isActive) {
      return 'var(--text-primary)';
    }

    return 'transparent';
  };

  return (
    <Box {...props}>
      <Link to={href}>
        <Typography
          size="lg"
          style={{
            borderBottom: '3px solid',
            borderColor: getBorderColor(),
            fontWeight,
          }}
        >
          {title}
        </Typography>
      </Link>
    </Box>
  );
};
