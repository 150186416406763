import { Tag, Typography } from '@/components';
import { TagVariant } from '@/types';
import { generateRandomString } from '@/utils';
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Image,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';

export interface IEntityCardProps {
  title: string;
  bodyContent: string[];
  tag?: string;
  apiStatus?: 'degraded' | 'down' | 'healthy' | 'no status';
  emptyBodyText?: string;
  tagVariant?: TagVariant;
}

export const EntityCard: FC<IEntityCardProps> = ({
  title,
  bodyContent,
  tag,
  tagVariant = 'neutral',
  apiStatus,
  emptyBodyText = 'No connections',
}) => {
  const getStatusColor = (): string => {
    switch (apiStatus) {
      case 'healthy':
        return 'var(--ds-color-core-green-700)';
      case 'degraded':
        return 'var(--ds-color-core-yellow-400)';
      case 'down':
        return 'var(--ds-color-core-red-700)';
      default:
        return 'var(--ds-color-core-slate-400)';
    }
  };

  return (
    <Card
      bg="var(--background-primary)"
      border="1px solid var(--border-primary)"
      boxShadow="md"
      h="full"
      variant="elevated"
    >
      <CardHeader p={0}>
        <Image h={200} objectFit="cover" src="/assets/api_card_bg.png" w="full" />
        <Box p={5} pb={0}>
          <Typography size="lg" style={{ fontWeight: 400 }} variant="heading">
            {title} (need to find a better image)
          </Typography>
        </Box>
      </CardHeader>
      <CardBody>
        {bodyContent.length > 0 ? (
          <VStack align="flex-start">
            {bodyContent.map((item) => (
              <Typography
                key={`${item}-${generateRandomString()}`}
                style={{
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {item}
              </Typography>
            ))}
          </VStack>
        ) : (
          <Typography style={{ fontStyle: 'italic', color: 'var(--text-secondary)' }}>
            {emptyBodyText}
          </Typography>
        )}
      </CardBody>
      <CardFooter justify={!apiStatus ? 'flex-end' : 'space-between'}>
        {tag && <Tag size="sm" text={tag} uppercase={false} variant={tagVariant} />}
        {apiStatus && (
          <HStack>
            <Box bg={getStatusColor()} h={2} w={2} />
            <Typography size="sm" style={{ textTransform: 'capitalize' }}>
              {apiStatus}
            </Typography>
          </HStack>
        )}
      </CardFooter>
    </Card>
  );
};
